import React from "react";
import {FormattedMessage} from "react-intl";

interface IProps {
    title: string;
    groups?: any[];
    arrows?: boolean;
    autoplay?: boolean;
    onNext?: () => void;
    onPrev?: () => void;
    onGroupClick?: (() => void) | undefined;
}

function BlockHeaderCustom({title}: IProps) {
    // const name = props.title.props.defaultMessage;
    // const id = props.title.props.id;

    return (
        title ? <div className="block-header">
            <h2 className="block-header__title__custom">
                <span className="title-line-fms"></span>
                <span className="title-name-fms">
                    {" "}
                    {/*<FormattedMessage id={id} defaultMessage={name}/>*/}
                    {title}
                </span>
                <span className="title-line-fms"></span>
            </h2>
        </div> : null
    );
}

export default BlockHeaderCustom;
